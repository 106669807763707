import React, { useEffect, useState } from "react"
import Header from "../../../components/Yard/header/Header"
import "./Home.css"
import HomeCard from "./HomeCard"
import { Routes } from "../../../utils/route"
import { Spin } from "antd"
import { usePosition } from "../../../utils/usePosition"
import { fetchYardList } from "../../../actions/yardActions/YardActions"
import { Input } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { IndustryType, RAIL_CAR } from "../../../utils/helper"

const Home = props => {
  const defaultSettings = {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  }
  const dispatch = useDispatch()
  const yardHandData = useSelector(state => state.yardHandData)
  const { latitude, longitude } = usePosition(false, defaultSettings)
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
      }
    }
    Routes("yard", callback)
  }, [])

  useEffect(() => {
    dispatch(fetchYardList())
  }, [])
  const getYardHandData = () => {
    if (searchTerm === "") {
      return yardHandData
    } else {
      return yardHandData?.filter(data => {
        return (
          data.asset_details?.equipment_no
            ?.toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1 ||
          data.customer_details?.name
            ?.toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1 ||
          data.asset_details?.equipment_type_details?.param_description
            ?.toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1
        )
      })
    }
  }
  if (!loading) {
    return (
      <>
        <section className="home">
          <Header />
          <div className="home_container">
            <div className="search-container">
              <Input
                style={{
                  paddingLeft: IndustryType() == RAIL_CAR ? "23" : "",
                  border: IndustryType() == RAIL_CAR ? "1px solid #8B8FAE" : "",
                }}
                placeholder={
                  IndustryType() == RAIL_CAR
                    ? "Enter Keyword"
                    : "Search Keyword"
                }
                bordered={true}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                suffix={<SearchOutlined />}
              />
            </div>
            <HomeCard
              yardHandData={getYardHandData()}
              inSearchState={searchTerm !== ""}
            />
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}
export default Home
